import {inject, Injectable} from "@angular/core";
import {ApiService, StorageService} from "@app/services";
import {firstValueFrom, Observable} from "rxjs";
import {News} from "@app/interfaces";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {environment} from "@env/environment";
import {TimelineEntry} from "@interfaces/timeline-entry";
import {NewsRepository} from "@app/repositories/news.repository";

@Injectable({
  providedIn: 'root'
})
export class NewsService extends ApiService {
  private storage: StorageService = inject(StorageService);
  private newsRepository: NewsRepository = inject(NewsRepository);

  fetch(): Observable<News[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/news`);
  }

  async getNews(): Promise<News[]> {
    let updatedAt: Date = await this.storage.get('news_updated_at');
    if (!updatedAt || (updatedAt && updatedAt.getTime() < new Date().getTime() - 1000 * 60 * 15)) { // 15 minutes
      console.log(`${this.constructor.name}.getNews loading new news from API`);
      const news = await this.getAllFromApi();
      this.newsRepository.updateNews(news);

      return news;
    }

    let news: News[] = await this.storage.get('news');
    console.log(`${this.constructor.name}.getNews news`, news.length);

    if (!news || news.length === 0) {
      news = await this.getAllFromApi();
      console.log(`${this.constructor.name}.getNews from API`, news);
    }

    this.newsRepository.updateNews(news);

    return news || [];
  }

  private cacheNewsCollection(entries: News[]) {
    console.log(`${this.constructor.name}.cacheNews`);
    this.storage.set('news', entries);
    this.storage.set('news_updated_at', new Date());
  }

  private async cacheNewsItem(entry: News) {
    console.log(`${this.constructor.name}.cacheNewsItem`, entry);
    let news = await this.storage.get('news');
    if (news) {
      news = news.filter((newsEntry: News) => newsEntry.id !== entry.id);
      news.push(entry);
      news = news.sort((a: News, b: News) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
      this.storage.set('news', news);
    } else {
      this.storage.set('news', [entry]);
    }
  }

  async getOneFromApi(id: string): Promise<News> {
    console.log(`${this.constructor.name}.getOneFromApi`, id);
    const news = await firstValueFrom(this.get(`/news/${id}`));

    if (news) {
      this.newsRepository.addNews(news);
    }

    return news;
  }

  private async getAllFromApi(): Promise<News[]> {
    return firstValueFrom(this.fetch()).then((fetchedEntries: News[]) => {
      this.cacheNewsCollection(fetchedEntries);
      console.log(`${this.constructor.name}.getAllFromApi`, fetchedEntries.length);

      return fetchedEntries;
    });
  }

  async clearCache() {
    this.storage.remove('news');
    this.storage.remove('news_updated_at');
  }

  create(news: News): Observable<News> {
    console.log(`${this.constructor.name}.create`, news);
    return this.post('/news', news);
  }

  update(news: News): Observable<News> {
    console.log(`${this.constructor.name}.update`, news);
    return this.put(`/news/${news.id}`, news);
  }

  async fetchBySlug(placeSlug: string, newsSlug: string) {
    console.log(`${this.constructor.name}.fetchBySlug`);
    let news = await this.storage.get('news');
    if (news) {
      const newsEntry = news.find((newsEntry: News) => newsEntry.slug === newsSlug);
      if (newsEntry) {
        return newsEntry;
      }
    }

    console.log(`${this.constructor.name}.fetchBySlug`);
    const newsEntry = await firstValueFrom(this.get(`/news/${newsSlug}`));
    if (newsEntry) {
      await this.cacheNewsItem(newsEntry);
      this.newsRepository.addNews(newsEntry);
    }

    return newsEntry;
  }

  uploadVideo(news: News, video: PickedFile): Observable<any> {
    console.log(`${this.constructor.name}.uploadVideo`, news.id, video);

    if (video.blob === undefined) {
      throw new Error('No video blob found');
    }

    const formData = new FormData();
    formData.append('video', video.blob, video.name);

    const url = `${environment.apiBaseUrl}/news/${news.id}/videos`;

    return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
  }

  async toggleLike(entry: TimelineEntry): Promise<News> {
    console.log(`${this.constructor.name}.toggleLike`);
    const news = await firstValueFrom(this.post(`/news/${entry.id}/like`, {}));

    await this.cacheNewsItem(news);

    return news;
  }
}
