import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {
  AlertController,
  IonApp,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  Platform
} from "@ionic/angular/standalone";
import {AuthService, MessagingService} from "@app/services";
import {SwPush, SwUpdate} from "@angular/service-worker";
import {interval} from "rxjs";
import {CacheService} from "@services/cache.service";
import {register} from 'swiper/element/bundle';
import {A2hsBrowserPromptComponent} from "@app/components/a2hs-browser-prompt/a2hs-browser-prompt.component";
import {RouterLink} from "@angular/router";
import {flare} from "@flareapp/flare-client";
import {EventsService, NewsService, PlacesService} from "@services/api";
import {OnboardingSheetService} from "@services/onboarding-sheet.service";
import {environment} from "@env/environment";

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: true,
  imports: [IonApp, IonRouterOutlet, A2hsBrowserPromptComponent, IonSplitPane, IonMenu, IonContent, IonList, IonMenuToggle, IonItem, IonLabel, RouterLink, IonFooter],
})
export class AppComponent implements OnInit, OnDestroy {

  private platform: Platform = inject(Platform);
  private authService: AuthService = inject(AuthService);
  private swUpdate: SwUpdate = inject(SwUpdate);
  private swPush: SwPush = inject(SwPush);
  private alertCtrl: AlertController = inject(AlertController);
  private cacheService: CacheService = inject(CacheService);
  private messagingService: MessagingService = inject(MessagingService);

  private newsService: NewsService = inject(NewsService);
  private eventsService: EventsService = inject(EventsService);
  private placesService: PlacesService = inject(PlacesService);

  private onboardingSheetService: OnboardingSheetService = inject(OnboardingSheetService);

  private updateInterval: any;

  constructor() {
    console.log(`${this.constructor.name}.constructor`);
    this.initializeApp();
  }

  async ngOnInit() {
    console.log(`${this.constructor.name}.ngOnInit`);

    // check for app updates every 15 minutes
    this.updateInterval = interval(60000 * 15).subscribe(() => {
      this.checkAppUpdate();
    });

    await this.checkAppUpdate();

    this.swPush.messages.subscribe((message) => {
      console.log(`${this.constructor.name}.swPush.messages`, message);
    });

    this.swPush.notificationClicks.subscribe((click) => {
      console.log(`${this.constructor.name}.swPush.notificationClicks`, click);
    });

    await this.newsService.getNews();
    await this.eventsService.getEvents();
    await this.placesService.getPlaces();
  }

  async initializeApp() {
    console.log(`${this.constructor.name}.initializeApp`);

    if (environment.production) {
      console.log(`${this.constructor.name}.initializeApp, flare enabled`);
      flare.light('0WIrhRmTZ6uois91bv71Ehj7BfFoKInM');
    }

    this.platform.ready().then(async () => {
      console.log(`${this.constructor.name}.initializeApp, platform ready`);


      await this.authService.checkAccessToken();
      await this.messagingService.getToken();
    });

    setTimeout(async () => await this.onboardingSheetService.maybeShowOnboarding(), 1000);
  }

  ngOnDestroy() {
    console.log(`${this.constructor.name}.ngOnDestroy`);
    this.updateInterval.unsubscribe();
  }

  async checkAppUpdate() {
    if (this.swUpdate.isEnabled) {
      const available = await this.swUpdate.checkForUpdate();
      console.log('new version available:', available);
      if (available) {
        const alert = await this.alertCtrl.create({
          header: 'Update verfügbar',
          message: 'Eine neue Version der App ist verfügbar. Möchtest du die App jetzt aktualisieren?',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Aktualisieren',
              role: 'confirm',
              handler: async () => {
                await this.swUpdate.activateUpdate();
                await this.cacheService.clearCache();

                const url = window.location.href;
                const newUrl = this.urlWithRndQueryParam(url);
                await fetch(newUrl, {
                  headers: {
                    Pragma: 'no-cache',
                    Expires: '-1',
                    'Cache-Control': 'no-cache',
                  },
                });
                window.location.href = url;
                window.location.reload();
              }
            }
          ]
        });
        await alert.present();
      }
    }
  }

  urlWithRndQueryParam(url: string, paramName: string = '_z') {
    const ulrArr = url.split('#');
    const urlQry = ulrArr[0].split('?');
    const usp = new URLSearchParams(urlQry[1] || '');
    usp.set(paramName || '_z', `${Date.now()}`);
    urlQry[1] = usp.toString();
    ulrArr[0] = urlQry.join('?');
    return ulrArr.join('#');
  }
}
