import {inject, Injectable} from "@angular/core";
import {firstValueFrom, Observable} from "rxjs";
import {Event} from "@app/interfaces";
import {ApiService} from "@services/api.service";
import {StorageService} from "@app/services";
import {environment} from "@env/environment";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {TimelineEntry} from "@interfaces/timeline-entry";
import {EventsRepository} from "@app/repositories/events.repository";


@Injectable({
  providedIn: 'root'
})
export class EventsService extends ApiService {

  private storage: StorageService = inject(StorageService);
  private eventsRepository: EventsRepository = inject(EventsRepository);

  fetch(): Observable<Event[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/events`);
  }

  async getEvents(): Promise<Event[]> {
    let updatedAt: Date = await this.storage.get('events_updated_at');
    if (!updatedAt || (updatedAt && updatedAt.getTime() < new Date().getTime() - 1000 * 60 * 15)) { // 15 minutes
      console.log(`${this.constructor.name}.getEvents loading new events from API`);
      const events = await this.getAllFromApi();
      this.eventsRepository.updateEvents(events);

      return events;
    }

    let events: Event[] = await this.storage.get('events');
    console.log(`${this.constructor.name}.getEvents events`, events.length);

    if (!events || events.length === 0) {
      events = await this.getAllFromApi();
      console.log(`${this.constructor.name}.getEvents from API`, events.length);
    }

    this.eventsRepository.updateEvents(events);

    return events || [];
  }

  private cacheEventsCollection(events: Event[]) {
    console.log(`${this.constructor.name}.cacheEvents`);
    this.storage.set('events', events);
    this.storage.set('events_updated_at', new Date());
  }

  private async cacheEventItem(entry: Event) {
    console.log(`${this.constructor.name}.cacheNewsItem`, entry);
    let events = await this.storage.get('events');
    if (events) {
      events = events.filter((event: Event) => event.id !== entry.id);
      events.push(entry);
      events = events.sort((a: Event, b: Event) => new Date(a.event_date_start).getTime() - new Date(b.event_date_start).getTime());
      this.storage.set('events', events);
    } else {
      this.storage.set('events', [entry]);
    }
  }

  async getOneFromApi(id: string): Promise<Event> {
    console.log(`${this.constructor.name}.getOneFromApi`, id);
    const event = await firstValueFrom(this.get(`/events/${id}`));

    if(event) {
      this.eventsRepository.addEvent(event);
    }

    return event;
  }

  private async getAllFromApi(): Promise<Event[]> {
    return firstValueFrom(this.fetch()).then((fetchedEvents: Event[]) => {
      this.cacheEventsCollection(fetchedEvents);
      console.log(`${this.constructor.name}.getAllFromApi`, fetchedEvents);

      return fetchedEvents;
    });
  }

  async clearCache() {
    console.log(`${this.constructor.name}.clearCache`);
    this.storage.remove('events');
    this.storage.remove('events_updated_at');
  }

  create(event: Event): Observable<Event> {
    console.log(`${this.constructor.name}.create`, event);
    return this.post(`/events`, event);
  }

  update(event: Event): Observable<Event> {
    console.log(`${this.constructor.name}.update`, event);
    return this.put(`/events/${event.id}`, event);
  }

  uploadVideo(event: Event, video: PickedFile): Observable<any> {
    console.log(`${this.constructor.name}.uploadVideo`, event.id, video);

    if (video.blob === undefined) {
      throw new Error('No video blob found');
    }

    const formData = new FormData();
    formData.append('video', video.blob, video.name);

    const url = `${environment.apiBaseUrl}/events/${event.id}/videos`;

    return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
  }

  async toggleLike(entry: TimelineEntry): Promise<Event> {
    console.log(`${this.constructor.name}.toggleLike`);
    const news = await firstValueFrom(this.post(`/events/${entry.id}/like`, {}));

    await this.cacheEventItem(news);

    return news;
  }
}
