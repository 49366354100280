import {APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, isDevMode} from '@angular/core';
import {environment} from '@env/environment';
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "@app/app.component";
import {provideRouter, RouteReuseStrategy} from "@angular/router";
import {IonicRouteStrategy, provideIonicAngular} from "@ionic/angular/standalone";
import {routes} from "./app.routes";
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideServiceWorker} from '@angular/service-worker';
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {provideIcons} from "@ng-icons/core";
import {
  heroAdjustmentsHorizontal,
  heroBell,
  heroBellSlash,
  heroBookmark,
  heroCalendar,
  heroCalendarDays,
  heroChevronLeft,
  heroChevronRight,
  heroClock,
  heroEnvelope,
  heroEye,
  heroEyeSlash,
  heroHeart,
  heroMagnifyingGlass,
  heroMagnifyingGlassCircle,
  heroMapPin,
  heroNewspaper, heroPencilSquare,
  heroPhone,
  heroPlus,
  heroShare,
  heroUser,
  heroXMark,
} from "@ng-icons/heroicons/outline";
import {heroHeartSolid} from "@ng-icons/heroicons/solid";
import {AuthInterceptor} from "@app/interceptors/auth-interceptor";
import {LoadingInterceptor} from "@app/interceptors/loading-interceptor";
import {IonicStorageModule} from "@ionic/storage-angular";
import {defineCustomElements} from '@ionic/pwa-elements/loader';
import {A2hsService, StorageService} from "@app/services";
import {ErrorHandlerDebug} from "@app/utils/error-handler-debug";
import {NgxSeoModule} from "@avivharuzi/ngx-seo";
import {CalendarModule, DateAdapter} from "angular-calendar";
import {adapterFactory} from "angular-calendar/date-adapters/date-fns";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {enableElfProdMode} from "@ngneat/elf";
import {ErrorHandlerFlare} from "@app/utils/error-handler-flare";

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
defineCustomElements(window);

if (environment.production) {
  enableProdMode();
  enableElfProdMode();
}

const ERROR_HANDLER = environment.production ? ErrorHandlerFlare : ErrorHandlerDebug;

bootstrapApplication(AppComponent, {
  providers: [
    {provide: ErrorHandler, useClass: ERROR_HANDLER},
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [StorageService, A2hsService],
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    provideIonicAngular(),
    provideRouter(routes),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:5000'
    }),
    importProvidersFrom(IonicStorageModule.forRoot({
      name: '89284-app-db',
    })),
    importProvidersFrom(NgxSeoModule.forRoot()),
    importProvidersFrom(CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })),
    importProvidersFrom([BrowserAnimationsModule]),
    provideIcons({
      heroAdjustmentsHorizontal,
      heroBookmark,
      heroCalendar,
      heroCalendarDays,
      heroClock,
      heroHeart,
      heroHeartSolid,
      heroMapPin,
      heroNewspaper,
      heroUser,
      heroPlus,
      heroMagnifyingGlass,
      heroMagnifyingGlassCircle,
      heroPhone,
      heroEnvelope,
      heroShare,
      heroBell,
      heroBellSlash,
      heroEye,
      heroEyeSlash,
      heroXMark,
      heroChevronLeft,
      heroChevronRight,
      heroPencilSquare,
    })
  ]
});

export function appInitializer(storageService: StorageService, a2hsService: A2hsService) {
  return async () => {
    console.log('appInitializer');
    await storageService.init();

    console.log(`appInitializer: registerPwaInstallEventListener`);

    a2hsService.checkUserAgent();
    a2hsService.trackStandalone();

    window.addEventListener('beforeinstallprompt', (e) => {

      // show the add button
      console.log(`appInitializer.registerPwaInstallEventListener.beforeinstallprompt`, e)
      a2hsService.promptIntercepted = true;
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      // no matter what, the snack-bar shows in 68 (06/16/2018 11:05 AM)
      e.preventDefault();
      // Stash the event so it can be displayed when the user wants.
      a2hsService.deferredPrompt = e;
      a2hsService.promptSaved = true;

    });
    window.addEventListener('appinstalled', (evt) => {
      a2hsService.trackInstalled();
      // hide the add button
      // a2hs.promptIntercepted = false;
    });
  }
}
