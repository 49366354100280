import {Injectable} from "@angular/core";
import {createState, select, Store, withProps} from "@ngneat/elf";
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";

interface Settings {
  hasSeenOnboarding: boolean;
}

const {state, config} = createState(withProps<Settings>({hasSeenOnboarding: false}));
const settingsStore = new Store({name: 'settings', state, config});

const persist = persistState(settingsStore, {
  key: 'settings',
  storage: localStorageStrategy,
});

@Injectable({providedIn: 'root'})
export class SettingsRepository {
  settings$ = settingsStore.pipe(select((state) => state));

  setHasSeenOnboarding() {
    settingsStore.update((state) => ({
      ...state,
      hasSeenOnboarding: true,
    }));
  }

  constructor() {
    console.log(`${this.constructor.name}.constructor`, settingsStore);
    this.settings$.subscribe((settings) => {
      console.log(`${this.constructor.name}.settings$`, settings);
    });
  }

  updateSettings(settings: Settings) {
    console.log(`${this.constructor.name}.updateSettings`, settings);
  }
}
