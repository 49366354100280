import {inject, Injectable} from "@angular/core";
import {firstValueFrom, Observable} from "rxjs";
import {Place, User} from "@app/interfaces";
import {ApiService, StorageService} from "@app/services";
import {PickedFile} from "@capawesome/capacitor-file-picker";
import {environment} from "@env/environment";
import {PlacesRepository} from "@app/repositories/places.repository";

@Injectable({
  providedIn: 'root'
})
export class PlacesService extends ApiService {

  private storage: StorageService = inject(StorageService);
  private placesRepository: PlacesRepository = inject(PlacesRepository);

  fetch(): Observable<Place[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/places?include=categories,locations,people`);
  }

  async getPlaces(): Promise<Place[]> {
    let updatedAt: Date = await this.storage.get('places_updated_at');
    if (!updatedAt || (updatedAt && updatedAt.getTime() < new Date().getTime() - 1000 * 60 * 60 * 24)) {
      console.log(`${this.constructor.name}.getPlaces loading new places from API`);
      const places = await this.getAllFromApi();
      this.placesRepository.updateEntries(places);

      return places;
    }

    let places: Place[] = await this.storage.get('places');
    console.log(`${this.constructor.name}.getPlaces places`, places.length);

    if (!places || places.length === 0) {
      places = await this.getAllFromApi();
      console.log(`${this.constructor.name}.getPlaces from API`, places.length);
    }

    this.placesRepository.updateEntries(places);

    return places || [];
  }

  async requestAccess(place: Place, user: User): Promise<{ success: Boolean }> {
    console.log(`${this.constructor.name}.requestAccess`, place, user);
    return firstValueFrom(this.post(`/places/request-access`, {place_id: place.id}));
  }

  private cachePlaces(places: Place[]) {
    console.log(`${this.constructor.name}.cachePlaces`);
    this.storage.set('places', places);
    this.storage.set('places_updated_at', new Date());
  }

  private async getAllFromApi(): Promise<Place[]> {
    return firstValueFrom(this.fetch()).then((fetchedEntries: Place[]) => {
      this.cachePlaces(fetchedEntries);
      console.log(`${this.constructor.name}.getPlacesFromApi`, fetchedEntries);

      return fetchedEntries;
    });
  }

  async clearCache() {
    this.storage.remove('places');
    this.storage.remove('places_updated_at');
  }

  uploadVideo(place: Place, video: PickedFile): Observable<any> {
    console.log(`${this.constructor.name}.uploadVideo`, place.id, video);

    if (video.blob === undefined) {
      throw new Error('No video blob found');
    }

    const formData = new FormData();
    formData.append('video', video.blob, video.name);

    const url = `${environment.apiBaseUrl}/places/${place.id}/videos`;

    return this.http.post(url, formData, {reportProgress: true, observe: 'events'});
  }
}
