import {inject, Injectable} from "@angular/core";
import {EventsService, NewsService, PlacesService} from "@services/api";
import {SwUpdate} from "@angular/service-worker";
import {AuthService} from "@services/auth.service";

@Injectable({providedIn: 'root'})
export class CacheService {
  private placesService: PlacesService = inject(PlacesService);
  private newsService: NewsService = inject(NewsService);
  private eventsService: EventsService = inject(EventsService);
  private authService: AuthService = inject(AuthService);
  private sw: SwUpdate = inject(SwUpdate);

  async clearCache() {
    this.placesService.clearCache();
    this.newsService.clearCache();
    this.eventsService.clearCache();

    await this.newsService.getNews();
    await this.eventsService.getEvents();
    await this.placesService.getPlaces();

    await this.authService.checkAccessToken();

    const updateAvailable = await this.sw.checkForUpdate();
    if (updateAvailable) {
      await this.sw.activateUpdate();
      window.location.reload();
    }
  }
}
